/**
 * This file is used to combine main requirejs dependencies for production
 */
require([
    'jquery',
    'assets/js/common/router/index',
    'assets/js/modules/browser',
    'APP_THEME/_common/base/header/index',
    'APP_THEME/_common/base/footer/index'
], function($, router, browser, { default: Header }) {
    //weak clickjacking protection
    if (document.location.hostname == 'farheap.marketing.adobe.com' || document.location.hostname == 'webvisor.com') {
        if (top.location != location) {
            top.location.href = location.href;
        }
    }

    //check for browser
    if (browser.isNotSupported()) {
        var page = '/' + window.location.pathname.split('/').pop();
        if (page != router.generate('/browser-not-supported')) {
            $('.old-browser-warning').removeClass('hidden');
        }
    }

    $(function() {
        Header.init();
        // Handle product group selectors on various static pages
        var list = $('select#product-group-jump-list');
        if (list.length > 0) {
            // Require the selectBoxIt dependency asynchronously
            require(['selectboxit/src/javascripts/jquery.selectBoxIt.min'], function() {
                list.selectBoxIt({ autoWidth: false });
                list.on('change', function() {
                    $('#product-group-jump-button').attr('href', $('option:selected', this).data('target'));
                });
            });
        }

        $('.js-go-back').on('click', function(event) {
            event.preventDefault();
            window.history.back();
        });
    });

    if (ONP.config.liveAssist.enabled) {
        Promise.all([import('APP_THEME/_common/live_assist/index'), import('assets/js/common/storage/cookie')]).then(
            ([{ default: LiveAssist }, cookie]) => {
                LiveAssist.init(ONP.config.liveAssist.accountNumber, [
                    {
                        type: 'ctmrinfo',
                        info: {
                            ctype: ONP.user.vipLevel,
                            customerId: cookie.getItem('userId'),
                            loginStatus: ONP.user.loggedIn,
                            userName: ONP.user.email,
                            visitorName: ONP.user.firstName + ' ' + ONP.user.lastName
                        }
                    },
                    {
                        type: 'personal',
                        personal: {
                            firstname: ONP.user.firstName,
                            lastname: ONP.user.lastName
                        }
                    }
                ]);
            }
        );
    }
});
